import logo from "./mslogo.png";

import javalogo from "./skillsImages/javaLogo.png"
import springlogo from "./skillsImages/springLogo.svg"
import jslogo from "./skillsImages/jsLogo.png"
import reactlogo from "./skillsImages/logo.svg"
import nodelogo from "./skillsImages/nodeLogo.svg"
import pythonlogo from "./skillsImages/pythonLogo.png"
import phplogo from "./skillsImages/phpLogo.svg"
import laravellogo from "./skillsImages/laravelLogo.svg"
import cpplogo from "./skillsImages/cppLogo.png"
import flutterlogo from "./skillsImages/flutterLogo.svg"
import postgreslogo from "./skillsImages/postgresLogo.png"
import mongodblogo from "./skillsImages/mangoLogo.svg"
import firebaselogo from "./skillsImages/firebaseLogo.svg"
import gitlogo from "./skillsImages/gitLogo.png"
import nwbadge from "./skillsImages/nwBadge.png"
import ccbadge from "./skillsImages/ccBadge.png"
import linuxlogo from "./skillsImages/linuxLogo.png"
import nginxlogo from "./skillsImages/nginxLogo.svg"
import f5logo from "./skillsImages/f5Logo.svg"
import a10logo from "./skillsImages/A10Logo.png"
import apachelogo from "./skillsImages/apacheLogo.png"
import pslogo from "./skillsImages/psLogo.svg"
import illuslogo from "./skillsImages/illustratorLogo.svg"
import anilogo from "./skillsImages/animateLogo.svg"
// import inksscapelogo from "./skillsImages/inkscapeLogo.svg"
import starumllogo from "./skillsImages/starumlLogo.png"
import "./App.css";
import * as GRIcons from "react-icons/gr";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="intro-header">
          <div className="intro-header-left">
            <img src={logo} className="App-logo" alt="logo" />
            <text className="mainUsername">{/* Manandhar Sudip */}</text>
            <br />
            <text className="subHead">Computer Engineer</text>
          </div>
          <div className="intro-header-right">
            <button className="download-btn">
              <text>Download my CV</text>
              <GRIcons.GrDocumentDownload />
            </button>
          </div>
        </div>
        <div className="intro-body">
          <text className="aboutMe">About Me</text>
          <p>
            Hello to anyone who happens to be reading this. Sudip Manandhar, a
            Computer Engineer, here. I began my engineering career four years
            ago out of a desire to do good. Now on the verge of turning it into
            a career. A few years ago, I started learning/developing websites
            and mobile applications. Growing in this ever-expanding technology
            domain has led me to the most recent and exciting fields of IT, such
            as AI and Machine Learning. Also, I'm fascinated by Networking,
            System Administration and Cybersecurity. Currently, learning Java,
            SpringBoot Framework.
          </p>
        </div>

        <div className="overview">
          <div className="overview-1">
            <h4>Up to now...</h4>
            <ul>
              <li>Birth: 1999, Dec 13</li>
              <li>Finished Schooling: 2018</li>
              <li>Started Computer Engineering, 2018</li>
              <li>Got Bachelors Degree, 2023</li>
              <li>Joined Astral Computers, 2023</li>
            </ul>
          </div>
          <div className="overview-2">
            <h4>Passionate About...</h4>
            <ul>
              <li>Coding</li>
              <li>Problem solving</li>
              <li>Developing</li>
              <li>Designing</li>
              <li>Playing Football, Chess</li>
              <li>Exploring</li>
            </ul>
          </div>
          <div className="overview-3">
            <h4>Interested In...</h4>
            <ul>
              <li>Web3</li>
              <li>AI</li>
              <li>Travelling</li>
            </ul>
          </div>
        </div>

        <div className="skillsDepartment">
          <img src={javalogo} className="Skill-logo-SD" alt="logo" />
          <img src={springlogo} className="Skill-logo-SD" alt="logo" />
          <img src={jslogo} className="Skill-logo-SD" alt="logo" />
          <img src={reactlogo} className="Skill-logo-SD" alt="logo" />
          <img src={nodelogo} className="Skill-logo-SD" alt="logo" />
          <img src={pythonlogo} className="Skill-logo-SD" alt="logo" />
          <img src={phplogo} className="Skill-logo-SD" alt="logo" />
          <img src={laravellogo} className="Skill-logo-SD" alt="logo" />
          <img src={cpplogo} className="Skill-logo-SD" alt="logo" />
          <img src={flutterlogo} className="Skill-logo-SD" alt="logo" />
          <img src={postgreslogo} className="Skill-logo-SD" alt="logo" />
          <img src={mongodblogo} className="Skill-logo-SD" alt="logo" />
          <img src={firebaselogo} className="Skill-logo-SD" alt="logo" />
          <img src={gitlogo} className="Skill-logo-SD" alt="logo" />
          <img src={linuxlogo} className="Skill-logo-SD" alt="logo" />
          <img src={nginxlogo} className="Skill-logo-SD" alt="logo" />
          <img src={f5logo} className="Skill-logo-SD" alt="logo" />
          <img src={a10logo} className="Skill-logo-SD" alt="logo" />
          <img src={apachelogo} className="Skill-logo-SD" alt="logo" />
          <img src={pslogo} className="Skill-logo-SD" alt="logo" />
          <img src={anilogo} className="Skill-logo-SD" alt="logo" />
          <img src={illuslogo} className="Skill-logo-SD" alt="logo" />
          <img src={starumllogo} className="Skill-logo-SD" alt="logo" />
          <img src={nwbadge} className="Skill-logo-SD" alt="logo" />
          <img src={ccbadge} className="Skill-logo-SD" alt="logo" />
        </div>
      </header>
    </div>
  );
}

export default App;
